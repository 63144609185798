
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import {
  Input
} from 'antd';

import './record.scss';

function Record({
  datum,
  selectedModel,
  title,
  fields,
  records,
  onDelete,
  onUpdate
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [editingValues, setEditingValues] = useState(null);

  useEffect(() => {
    setEditingValues({ ...datum });
  }, [datum]);

  function enterEditingMode() {
    setEditingValues({
      ...datum
    });
    setIsEditing(true);
  }

  function changeNumberValue(fieldId, value) {
    const filteredValue = value.replace(/[^0-9.]/g, '');
    setEditingValues({
      ...editingValues,
      [fieldId]: filteredValue
    });
  }

  function changeStringValue(fieldId, value) {
    setEditingValues({
      ...editingValues,
      [fieldId]: value
    });
  }

  async function updateRecord() {
    onUpdate(datum.id, editingValues);
    setIsEditing(false);
  }

  async function deleteRecord(id) {
    let datumText = datum.title;
    if (datumText === '') datumText = '未命名';

    if (window.confirm(`确定要删除 "${datum.title}" 参数吗？`)) {
      onDelete(datum.id);
    } else {
      // do nothing
    }
  }

  const className = classnames({
    record: true,
    'is-editing': isEditing,
  });

  function renderInputParams(paramValue) {
    console.log('paramValue', paramValue);
    console.log('type', typeof paramValue);
    if (!paramValue) return;
    const replaceInputParameters = `${paramValue}`.replace(
      /{{INPUT.([0-9]+)}}/,
      (match, id) => {
        const field = selectedModel.inputParameters.find(
          (param) => `${param.id}` === `${id}`
        );
        console.log('field', field);
        if (!field) {
          return '未知参数';
        }
        console.log('field title', field.title);
        return `参数：${field.title}`;
      }
    );
    console.log('replaceInputParameters', replaceInputParameters);

    return (
      <div className="formula-display" dangerouslySetInnerHTML={{ __html: replaceInputParameters }}>
      </div>
    );
  }
  
  return (
    <div
      key={datum.id}
      className={className}
    >
      {
        fields.map((field, i) => {
          return (
            <div
              key={i}
              className={classnames({
                field: true,
                'is-id': field.id === 'id'
              })}
            >
              {
                isEditing && field.type === 'NUMBER' && field.id !== 'ID' &&
                <Input
                  value={editingValues[field.id]}
                  onChange={(e) => changeNumberValue(field.id, e.target.value)}
                />
              }
              {
                isEditing && field.type === 'STRING' && field.id !== 'ID' &&
                <Input
                  value={editingValues[field.id]}
                  onChange={(e) => changeStringValue(field.id, e.target.value)}
                />
              }
              {
                isEditing && field.id === 'ID' &&
                <div>{datum[field.id]}</div>
              }
              {
                !isEditing &&
                (
                  (field.id === 'conditionValue' || field.id === 'body') ?
                  renderInputParams(datum[field.id]) :
                  datum[field.id]
                )
              }
            </div>
          );
        })
      }
      <div className="actions">
        {
          isEditing ?
          (
            <>
              <div
                onClick={() => updateRecord()}
              >
                保存
              </div>
              <div
                onClick={() => setIsEditing(false)}
              >
                取消
              </div>
            </>
          ) :
          (
            <div
              onClick={() => enterEditingMode()}
            >
              编辑
            </div>
          )
        }
        <div
          onClick={() => deleteRecord(datum.id)}
        >
          删除
        </div>
      </div>
    </div>
  );
}

export default Record;
