
import React, { useState } from 'react';
import classnames from 'classnames';
import {
  Input
} from 'antd';

import request from '../adapters/api';

import './record.scss';

function Record({
  datum,
  selectedTable,
  records,
  setRecords
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [editingValues, setEditingValues] = useState(null);

  function enterEditingMode() {
    setEditingValues({
      ...datum.data
    });
    setIsEditing(true);
  }

  function changeNumberValue(fieldId, value) {
    const filteredValue = value.replace(/[^0-9.]/g, '');
    setEditingValues({
      ...editingValues,
      [fieldId]: filteredValue
    });
  }

  function changeStringValue(fieldId, value) {
    setEditingValues({
      ...editingValues,
      [fieldId]: value
    });
  }

  async function updateRecord() {
    await request(`data-table-records/${datum.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: editingValues
      })
    });

    setRecords(records.map((record) => {
      if (record.id === datum.id) {
        return {
          ...record,
          data: editingValues
        };
      } else {
        return record;
      }
    }));

    setIsEditing(false);
  }

  async function deleteRecord(id) {
    const tableTitle = selectedTable.title;

    let datumText = Object.keys(datum.data).map((fieldId) => {
      return datum.data[fieldId];
    }).join(', ');
    if (datumText === '') datumText = '空数据行';
    else datumText = ` ${datumText} `;

    if (window.confirm(`确定要删除 "${tableTitle}" 表中的${datumText}吗？`)) {
      await request(`data-table-records/${id}/delete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setRecords(records.filter((datum) => datum.id !== id));
    } else {
      // do nothing
    }
  }

  const className = classnames({
    record: true,
    'is-editing': isEditing,
  });

  return (
    <div
      key={datum.id}
      className={className}
    >
      {
        selectedTable.fields.map((field) => {
          return (
            <div
              key={field.id}
              className="field"
            >
              {
                isEditing && field.type === 'NUMBER' &&
                <Input
                  value={editingValues[field.id]}
                  onChange={(e) => changeNumberValue(field.id, e.target.value)}
                />
              }
              {
                isEditing && (field.type === 'STRING' || field.type === 'FORMULA') &&
                <Input
                  value={editingValues[field.id]}
                  onChange={(e) => changeStringValue(field.id, e.target.value)}
                />
              }
              {
                !isEditing &&
                datum.data[field.id]
              }
            </div>
          );
        })
      }
      <div className="actions">
        {
          isEditing ?
          (
            <>
              <div
                onClick={() => updateRecord()}
              >
                保存
              </div>
              <div
                onClick={() => setIsEditing(false)}
              >
                取消
              </div>
            </>
          ) :
          (
            <div
              onClick={() => enterEditingMode()}
            >
              编辑
            </div>
          )
        }
        <div
          onClick={() => deleteRecord(datum.id)}
        >
          删除
        </div>
      </div>
    </div>
  );
}

export default Record;
