
let domain = 'http://localhost:4500/';
if (process.env.NODE_ENV === 'production') {
  domain = 'https://poc-api.dingyuefeng.cn:4500/';
}

async function request(path, options) {
  try {
    const result = await fetch(domain + path, options);
    return result;
  } catch(e) {
    console.log(e);
    return { error: e };
  }
}

export default request;
