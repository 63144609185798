
import React, { useState } from 'react';
import {
  Button,
  Input,
  Tabs,
  ConfigProvider
} from 'antd';

import Calculator from './calculator';
import ModelSetup from './model-setup';
import DataTables from './data-tables';

import './container.scss';

const items = [
  {
    key: 'calculator',
    label: '计价器',
    children: <Calculator />
  },
  {
    key: 'model-setup',
    label: '计价模型设置',
    children: <ModelSetup />
  },
  {
    key: 'data-tables',
    label: '数据表',
    children: <DataTables />
  }
];

function Container() {
  const [auth, setAuth] = useState(false);
  const [password, setPassword] = useState('');

  function authenticate() {
    if (password === 'longipoc') {
      setAuth(true);
    }
  }

  function handlePasswordChange(e) {
    setPassword(e.target.value);
  }

  if (!auth) {
    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#ff662e'
          },
        }}
      >
        <div className="container password">
          <Input value={password} onChange={handlePasswordChange} />
          <Button type="primary" onClick={authenticate}>登录</Button>
        </div>
      </ConfigProvider>
    )
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#ff662e'
        },
      }}
    >
      <div className="container">
        <div className="nav-bar">
          <img src="/logo.png" alt="logo" />
        </div>
        <div className="content">
          <Tabs
            defaultActiveKey="calculator"
            destroyInactiveTabPane
            items={items}
          />
        </div>
      </div>
    </ConfigProvider>
  );
}

export default Container;
