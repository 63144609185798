
import React, { useState, useEffect } from 'react';
import {
  Input,
  Button,
  Popover
} from 'antd';

import './formula-editor.scss';

function FormulaEditor({
  selectedModel,
  formula,
  onDelete,
  onUpdate
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [editingValues, setEditingValues] = useState(formula);

  useEffect(() => {
    setEditingValues(formula);
  }, [formula]);

  function handleStartEditing() {
    setEditingValues(formula);
    setIsEditing(true);
  }

  function handleCancel() {
    setIsEditing(false);
  }

  function handleDelete() {
    if (window.confirm(`确定要删除 "${formula.title}" 公式吗？`)) {
      onDelete();
    } else {
      // do nothing
    }
  }

  function handleSave() {
    onUpdate(editingValues);
    setIsEditing(false);
  }

  function renderFormula(formula) {
    console.log('formula', formula);
    const {
      inputParameters, dataTableParameters, apiParameters
    } = selectedModel;

    const replaceInputParameters = `${formula}`.replace(
      /{{INPUT.([0-9]+)}}/g,
      (match, id) => {
        const field = inputParameters.find(
          (param) => `${param.id}` === `${id}`
        );
        if (!field) return <div data-param-type="input-param">未知参数</div>;
        console.log(field.title);
        return `<div data-param-type="input-param">${field.title}</div>`;
      }
    );
    console.log('replaceInputParameters', replaceInputParameters);

    const replaceDataTableParameters = replaceInputParameters.replace(
      /{{DATA_TABLE.([0-9]+)}}/g,
      (match, id) => {
        const field = dataTableParameters.find(
          (param) => `${param.id}` === `${id}`
        );
        if (!field) return <div data-param-type="input-param">未知参数</div>;
        console.log(field.title);
        return `<div data-param-type="input-param">${field.title}</div>`;
      }
    );
    console.log('replaceDataTableParameters', replaceDataTableParameters);
  
    const replaceApiParameters = replaceDataTableParameters.replace(
      /{{API.([0-9]+)}}/g,
      (match, id) => {
        const field = apiParameters.find(
          (param) => `${param.id}` === `${id}`
        );
        if (!field) return <div data-param-type="input-param">未知参数</div>;
        console.log(field.title);
        return `<div data-param-type="input-param">${field.title}</div>`;
      }
    );
    console.log('replaceApiParameters', replaceApiParameters);

    return (
      <div className="formula-display" dangerouslySetInnerHTML={{ __html: replaceApiParameters }}>
      </div>
    );
  }

  if (isEditing) {
    return (
      <div
        className="component-formula-editor is-editing"
      >
        <div className="title">
          <div>名称：</div>
          <div>
            <Input
              value={editingValues.title}
              onChange={(e) => setEditingValues({
                ...editingValues,
                title: e.target.value
              })}
            />
          </div>
        </div>
        <div className="body">
          <div>公式：</div>
          <div>
            <Input.TextArea
              style={{ height: '100%' }}
              value={editingValues.body}
              onChange={(e) => setEditingValues({
                ...editingValues,
                body: e.target.value
              })}
            />
          </div>
        </div>
        <div className="params-insert">
          <Popover
            placement="right"
            content={
              <div>
                {
                  selectedModel.inputParameters.map((param) => (
                    <div
                      className="popover-param-item"
                      key={param.id}
                      onClick={() => {
                        setEditingValues({
                          ...editingValues,
                          body: `${editingValues.body}{{INPUT.${param.id}}}`
                        });
                      }}
                    >
                      {param.title}
                    </div>
                  ))
                }
                {
                  selectedModel.dataTableParameters.map((param) => (
                    <div
                      className="popover-param-item"
                      key={param.id}
                      onClick={() => {
                        setEditingValues({
                          ...editingValues,
                          body: `${editingValues.body}{{DATA_TABLE.${param.id}}}`
                        });
                      }}
                    >
                      {param.title}
                    </div>
                  ))
                }
                {
                  selectedModel.apiParameters.map((param) => (
                    <div
                      className="popover-param-item"
                      key={param.id}
                      onClick={() => {
                        setEditingValues({
                          ...editingValues,
                          body: `${editingValues.body}{{API.${param.id}}}`
                        });
                      }}
                    >
                      {param.title}
                    </div>
                  ))
                }
              </div>
            }
          >
            <Button>
              添加参数
            </Button>
          </Popover>
        </div>
        <div className="actions">
          <Button onClick={handleSave} type="primary">保存</Button>
          <Button onClick={handleCancel}>取消</Button>
          <Button onClick={handleDelete} danger>删除</Button>
        </div>
      </div>
    );
  }

  return (
    <div
      className="component-formula-editor is-reading"
      onClick={handleStartEditing}
    >
      <div className="title">
        <div>名称：</div>
        <div>{formula.title}</div>
      </div>
      <div className="body">
        <div>公式：</div>
        <div>{renderFormula(formula.body)}</div>
      </div>
    </div>
  );
}

export default FormulaEditor;
