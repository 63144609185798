
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import {
  Button
} from 'antd';
import {
  PlusCircleOutlined
} from '@ant-design/icons';

import EditText from '../utils/edit-text';
import Record from './record';
import FormulaEditor from './formula-editor';
import request from '../adapters/api';

import './index.scss';

function ModelSetup() {
  const [selectedModelId, setSelectedModelId] = useState(1);
  const [models, setModels] = useState(null);

  let selectedModel;
  if (models !== null) {
    selectedModel = models.find(
      (model) => model.id === selectedModelId
    );
  }

  useEffect(() => {
    (async () => {
      const response = await request('models');
      if (!response.error) {
        const body = await response.json();
        setModels(body.data);
      }
    })();
  }, []);

  if (
    models === null
  ) {
    return (
      <div>加载中...</div>
    );
  }

  if (
    models.length === 0
  ) {
    return (
      <div className="page-model-setup empty">
        <div>无数据，请创建模型</div>
        <Button
          icon={<PlusCircleOutlined />}
          type="primary"
          onClick={createModel}
        >
          添加
        </Button>
      </div>
    )
  }

  async function createModel() {
    const response = await request('models', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (!response.error) {
      const body = await response.json();
      setModels([...models, body.data]);
    }
  }

  async function updateModel(values) {
    await request(`models/${selectedModel.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    });

    setModels(models.map((model) => {
      if (model.id === selectedModel.id) {
        return {
          ...model,
          ...values
        };
      } else {
        return model;
      }
    }));
  }

  async function deleteModel() {
    if (window.confirm(`确定要删除 "${selectedModel.title}" 模型吗？`)) {
      await request(`models/${selectedModel.id}/delete`, {
        method: 'POST'
      });
      const newModels = models.filter((model) => model.id !== selectedModel.id);
      setModels(newModels);
      setSelectedModelId(newModels[0].id);
    } else {
      // do nothing
    }
  }

  async function createInputParameter() {
    const { inputParameters, parametersCounter } = selectedModel;

    const newParametersCounter = parametersCounter + 1;
    const newInputParameters = [
      ...inputParameters,
      {
        id: newParametersCounter,
        title: `新建用户输入参数`,
        type: 'STRING'
      }
    ];

    updateModel({
      parametersCounter: newParametersCounter,
      inputParameters: newInputParameters
    });
  }

  async function createDataTableParameter() {
    const { dataTableParameters, parametersCounter } = selectedModel;

    const newParametersCounter = parametersCounter + 1;
    const newDataTableParameters = [
      ...dataTableParameters,
      {
        id: newParametersCounter,
        title: `新建数据查询参数`,
        tableId: 1,
        conditionFieldId: 1,
        conditionValue: 100,
        selectFieldId: 1
      }
    ];

    updateModel({
      parametersCounter: newParametersCounter,
      dataTableParameters: newDataTableParameters
    });
  }

  async function createApiParameter() {
    const { apiParameters, parametersCounter } = selectedModel;

    const newParametersCounter = parametersCounter + 1;
    const newApiParameters = [
      ...apiParameters,
      {
        id: newParametersCounter,
        title: `新建 API 查询参数`,
        url: `https://www.baidu.com`,
        method: 'GET',
        headers: '[{ Content-Type": "application/json" }]',
        body: '{ data: {{INPUT.1}} }',
        resValue: 'data.price'
      }
    ];

    updateModel({
      parametersCounter: newParametersCounter,
      apiParameters: newApiParameters
    });
  }

  async function createFormula() {
    const { formulae } = selectedModel;

    const newFormulae = [
      ...formulae,
      {
        title: `新建公式`,
        body: 100
      }
    ];

    updateModel({
      formulae: newFormulae
    });
  }

  async function deleteInputParameter(id) {
    const { inputParameters } = selectedModel;

    const newInputParameters = inputParameters.filter((param) => param.id !== id);

    updateModel({
      inputParameters: newInputParameters
    });
  }

  async function deleteDataTableParameter(id) {
    const { dataTableParameters } = selectedModel;

    const newDataTableParameters = dataTableParameters.filter((param) => param.id !== id);

    updateModel({
      dataTableParameters: newDataTableParameters
    });
  }

  async function deleteApiParameter(id) {
    const { apiParameters } = selectedModel;

    const newApiParameters = apiParameters.filter((param) => param.id !== id);

    updateModel({
      apiParameters: newApiParameters
    });
  }

  async function deleteFormula(index) {
    const { formulae } = selectedModel;

    const newFormulae = formulae.filter((formula, i) => i !== index);

    updateModel({
      formulae: newFormulae
    });
  }

  async function updateInputParameter(id, values) {
    const { inputParameters } = selectedModel;

    const newInputParameters = inputParameters.map((param) => {
      if (param.id === id) {
        return {
          ...param,
          ...values
        };
      } else {
        return param;
      }
    });

    updateModel({
      inputParameters: newInputParameters
    });
  }

  async function updateDataTableParameter(id, values) {
    const { dataTableParameters } = selectedModel;

    const newDataTableParameters = dataTableParameters.map((param) => {
      if (param.id === id) {
        return {
          ...param,
          ...values
        };
      } else {
        return param;
      }
    });

    updateModel({
      dataTableParameters: newDataTableParameters
    });
  }

  async function updateApiParameter(id, values) {
    const { apiParameters } = selectedModel;

    const newApiParameters = apiParameters.map((param) => {
      if (param.id === id) {
        return {
          ...param,
          ...values
        };
      } else {
        return param;
      }
    });

    updateModel({
      apiParameters: newApiParameters
    });
  }

  async function updateFormula(index, values) {
    const { formulae } = selectedModel;

    const newFormulae = formulae.map((formula, i) => {
      if (i === index) {
        return {
          ...formula,
          ...values
        };
      } else {
        return formula;
      }
    });

    updateModel({
      formulae: newFormulae
    });
  }

  console.log('selectedModel', selectedModel);

  return (
    <div className="page-model-setup">
      <div className="model-list">
        {
          models.map((model) => {
            const className = classnames({
              'model-list-item': true,
              'selected': model.id === selectedModelId
            })
            return (
              <div
                key={model.id}
                className={className}
                onClick={() => setSelectedModelId(model.id)}
              >
                {model.title}
              </div>
            );
          })
        }
         <Button
          icon={<PlusCircleOutlined />}
          type="primary"
          onClick={createModel}
        >
          添加
        </Button>
      </div>
      {
        !selectedModel &&
        <div className="model-details empty">请选择一个模型</div>
      }
      {
        selectedModel &&
        <div className="model-details">
          <div className="header">
            <div className="title">
              <div>模型名：</div>
              <EditText
                id={selectedModel.id}
                text={selectedModel.title}
                onUpdate={(title) => updateModel({ title })}
              />
            </div>
            <div className="actions">
              <Button
                danger
                onClick={deleteModel}
                disabled={models.length === 1}
              >
                删除模型
              </Button>
            </div>
          </div>

          <div className="editable-table formulae">
            <div className="title">公式</div>
            {
              selectedModel.formulae.map((formula, i) => (
                <FormulaEditor
                  key={i}
                  selectedModel={selectedModel}
                  formula={formula}
                  onDelete={() => deleteFormula(i)}
                  onUpdate={(values) => updateFormula(i, values)}
                />
              ))
            }
            <div className="actions">
              <Button
                icon={<PlusCircleOutlined />}
                onClick={createFormula}
              >
                添加公式
              </Button>
            </div>
          </div>

          <div className="editable-table input">
            <div className="title">用户输入参数</div>
            <div className="header">
              <div>ID</div>
              <div>名称</div>
              <div>类型</div>
            </div>
            <div className="body">
              {
                selectedModel.inputParameters.map((param, i) => (
                  <Record
                    selectedModel={selectedModel}
                    key={i}
                    datum={param}
                    title="用户输入参数"
                    fields={[
                      { type: 'NUMBER', id: 'id'},
                      { type: 'STRING', id: 'title' },
                      { type: 'STRING', id: 'type' }
                    ]}
                    records={selectedModel.inputParameters}
                    onDelete={deleteInputParameter}
                    onUpdate={updateInputParameter}
                  />
                ))
              }
            </div>
            <div className="actions">
              <Button
                icon={<PlusCircleOutlined />}
                onClick={createInputParameter}
              >
                添加用户输入参数
              </Button>
            </div>
          </div>

          <div className="editable-table data-table">
            <div className="title">数据表查询参数</div>
            <div className="header">
              <div>ID</div>
              <div>名称</div>
              <div>表名</div>
              <div>条件字段</div>
              <div>条件值</div>
              <div>取值</div>
            </div>
            <div className="body">
              {
                selectedModel.dataTableParameters.map((param, i) => (
                  <Record
                    key={i}
                    datum={param}
                    selectedModel={selectedModel}
                    title="数据库查询参数"
                    fields={[
                      { type: 'NUMBER', id: 'id'},
                      { type: 'STRING', id: 'title' },
                      { type: 'STRING', id: 'tableId' },
                      { type: 'STRING', id: 'conditionFieldId' },
                      { type: 'STRING', id: 'conditionValue' },
                      { type: 'STRING', id: 'selectFieldId' }
                    ]}
                    records={selectedModel.inputParameters}
                    onDelete={deleteDataTableParameter}
                    onUpdate={updateDataTableParameter}
                  />
                ))
              }
            </div>
            <div className="actions">
              <Button
                icon={<PlusCircleOutlined />}
                onClick={createDataTableParameter}
              >
                添加数据查询参数
              </Button>
            </div>
          </div>

          <div className="editable-table api">
            <div className="title">API 查询参数</div>
            <div className="header">
              <div>ID</div>
              <div>名称</div>
              <div>URL</div>
              <div>METHOD</div>
              <div>Headers</div>
              <div>Body</div>
              <div>Response</div>
            </div>
            <div className="body">
              {
                selectedModel.apiParameters.map((param, i) => (
                  <Record
                    key={i}
                    datum={param}
                    selectedModel={selectedModel}
                    title="API 查询参数"
                    fields={[
                      { type: 'NUMBER', id: 'id'},
                      { type: 'STRING', id: 'title' },
                      { type: 'STRING', id: 'url' },
                      { type: 'STRING', id: 'method' },
                      { type: 'STRING', id: 'headers' },
                      { type: 'STRING', id: 'body' },
                      { type: 'STRING', id: 'resValue' }
                    ]}
                    records={selectedModel.inputParameters}
                    onDelete={deleteApiParameter}
                    onUpdate={updateApiParameter}
                  />
                ))
              }
            </div>
            <div className="actions">
              <Button
                icon={<PlusCircleOutlined />}
                onClick={createApiParameter}
              >
                添加 API 查询参数
              </Button>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default ModelSetup;
