
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import {
  Button,
  Popover
} from 'antd';
import {
  PlusCircleOutlined
} from '@ant-design/icons';

import Record from './record';
import EditText from '../utils/edit-text';
import request from '../adapters/api';
import CreateFieldForm from './create-field-form';
import UpdateFieldForm from './update-field-form';

import './index.scss';

function DataTables() {
  const [selectedTableId, setSelectedTableId] = useState(1);
  const [records, setRecords] = useState(null);
  const [tables, setTables] = useState(null);

  let selectedTable, selectedTableData;
  if (tables !== null && records !== null) {
    selectedTable = tables.find(
      (table) => table.id === selectedTableId
    );
    selectedTableData = records.filter((datum) =>
      datum.tableId === selectedTableId
    );
  }

  useEffect(() => {
    (async () => {
      const response = await request('data-table-records');
      if (!response.error) {
        const body = await response.json();
        setRecords(body.data);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await request('data-tables');
      if (!response.error) {
        const body = await response.json();
        setTables(body.data);
      }
    })();
  }, []);

  if (
    tables === null ||
    records === null
  ) {
    return (
      <div>加载中...</div>
    );
  }

  if (
    tables.length === 0
  ) {
    return (
      <div className="page-data-tables empty">
        <div>无数据，请创建数据表</div>
        <Button
          icon={<PlusCircleOutlined />}
          type="primary"
          onClick={createTable}
        >
          添加
        </Button>
      </div>
    )
  }

  async function createTable() {
    const response = await request('data-tables', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (!response.error) {
      const body = await response.json();
      setTables([...tables, body.data]);
    }
  }

  async function handleAddField(title, type, fx) {
    const {
      fieldsCounter,
      fields
    } = selectedTable;

    const newFieldsCounter = fieldsCounter + 1;
    const newFields = [
      ...fields,
      { id: newFieldsCounter, title, type, fx }
    ];

    updateTable({
      fieldsCounter: newFieldsCounter,
      fields: newFields
    });
  }

  async function handleUpdateField(fieldId, title, type, fx) {
    const newFields = selectedTable.fields.map((field) => {
      if (field.id === fieldId) {
        if (type === 'FORMULA') {
          return {
            ...field,
            title,
            fx
          };
        }
        return {
          ...field,
          title
        };
      } else {
        return field;
      }
    });

    updateTable({
      fields: newFields
    });
  }

  async function handleDeleteField(fieldId) {
    const field = selectedTable.fields.find((field) => field.id === fieldId);
    if (window.confirm(`确定要删除 "${field.title}" 字段吗？`)) {
      const newFields = selectedTable.fields.filter((field) => field.id !== fieldId);

      updateTable({
        fields: newFields
      });
    } else {
      // do nothing
    }
  }

  async function updateTable(values) {
    const response = await request(`data-tables/${selectedTable.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    });
    if (!response.error) {
      const body = await response.json();
      setTables(tables.map((table) => {
        if (table.id === selectedTable.id) {
          return {
            ...table,
            ...values
          };
        } else {
          return table;
        }
      }));
    }
  }

  async function deleteTable() {
    if (window.confirm(`确定要删除 "${selectedTable.title}" 表吗？`)) {
      await request(`data-tables/${selectedTable.id}/delete`, {
        method: 'POST'
      });
      const newTables = tables.filter((table) => table.id !== selectedTable.id);
      setTables(newTables);
      setSelectedTableId(newTables[0].id);
    } else {
      // do nothing
    }
  }

  async function createRecord() {
    const response = await request('data-table-records', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        tableId: selectedTableId
      })
    });
    if (!response.error) {
      const body = await response.json();
      setRecords([...records, body.data]);
    }
  }

  return (
    <div className="page-data-tables">
      <div className="table-list">
        {
          tables.map((table) => {
            const className = classnames({
              'table-list-item': true,
              'selected': table.id === selectedTableId
            })
            return (
              <div
                key={table.id}
                className={className}
                onClick={() => setSelectedTableId(table.id)}
              >
                {table.title}
              </div>
            );
          })
        }
        <Button
          icon={<PlusCircleOutlined />}
          type="primary"
          onClick={createTable}
        >
          添加
        </Button>
      </div>
      {
        !selectedTable &&
        <div className="table-details">请选择一个数据表</div>
      } 
      {
        selectedTable &&
        <div className="table-details">
          <div className="header">
            <div className="title">
              <div>表名：</div>
              <EditText
                text={selectedTable.title}
                onUpdate={(title) => updateTable({ title })}
              />
            </div>
            <div className="actions">
              <Button
                danger
                onClick={deleteTable}
                disabled={tables.length === 1}
              >
                删除表
              </Button>
            </div>
          </div>
          <div className="description">
            ID：{selectedTable.id}
          </div>
          <div className="editable-table">
            <div className="header">
              {
                selectedTable.fields.map((field) => {
                  return (
                    <Popover
                      key={field.id}
                      placement="bottom"
                      content={
                        <UpdateFieldForm
                          id={field.id}
                          title={field.title}
                          type={field.type}
                          fx={field.fx}
                          onUpdate={(title) => handleUpdateField(field.id, title)}
                          onDelete={() => handleDeleteField(field.id)}
                        />
                      }
                    >
                      <div
                        className="field"
                      >
                        {field.title}
                      </div>
                    </Popover>
                  );
                })
              }
              <div className="add">
                <Popover
                  placement="rightTop"
                  content={
                    <CreateFieldForm
                      onCreate={handleAddField}
                    />
                  }
                >
                  <PlusCircleOutlined />
                </Popover>
              </div>
            </div>
            <div className="body">
              {
                selectedTableData.map((datum, i) => (
                  <Record
                    key={i}
                    datum={datum}
                    selectedTable={selectedTable}
                    records={records}
                    setRecords={setRecords}
                  />
                ))
              }
            </div>
            <div className="actions">
              <Button
                icon={<PlusCircleOutlined />}
                onClick={createRecord}
              >
                添加数据
              </Button>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default DataTables;
