
import React, { useState } from 'react';
import {
  Row, Col,
  Input,
  Button
} from 'antd';

import './update-field-form.scss';

function UpdateFieldForm({
  id,
  title,
  type,
  fx,
  onUpdate,
  onDelete
}) {
  const [editTitle, setEditTitle] = useState(title);
  const [editFx, setEditFx] = useState(fx);

  return (
    <div className="component-update-field-form">
      <Row>
        <Col span={24}>
          <div className="id">ID：{id}</div>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <div style={{ lineHeight: '32px' }}>名称</div>
        </Col>
        <Col span={18}>
          <Input
            value={editTitle}
            onChange={(e) => setEditTitle(e.target.value)}
          />
        </Col>
      </Row>
      {
        type === 'FORMULA' &&
        <Row>
          <Col span={24}>
            <Input.TextArea
              value={editFx}
              placeholder="f(x) = ..."
              autoSize={{ minRows: 3, maxRows: 5 }}
              onChange={(e) => setEditFx(e.target.value)}
            />
          </Col>
        </Row>
      }
      <Row gutter={10}>
        <Col span={12}>
          <Button
            style={{ width: '100%' }}
            type="primary"
            onClick={() => onUpdate(editTitle)}
          >
            更改
          </Button>
        </Col>
        <Col span={12}>
          <Button
            style={{ width: '100%' }}
            danger
            onClick={onDelete}
          >
            删除
          </Button>
        </Col>
      </Row>
      
    </div>
  );
}

export default UpdateFieldForm;
