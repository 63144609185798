
import React, { useState } from 'react';
import {
  Row, Col,
  Input,
  Radio,
  Button
} from 'antd';

import './create-field-form.scss';

function CreateFieldForm({
  onCreate
}) {
  const [title, setTitle] = useState('');
  const [type, setType] = useState('STRING');
  const [fx, setFx] = useState('');

  return (
    <div className="component-create-field-form">
      <Row>
        <Col span={6}>
          <div>名称</div>
        </Col>
        <Col span={18}>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Radio.Group
            className="custom-radio"
            onChange={(e) => setType(e.target.value)}
            value={type}
            optionType="button"
          >
            <Radio value='STRING'>文本</Radio>
            <Radio value='NUMBER'>数字</Radio>
            <Radio value='FORMULA'>f(x)</Radio>
          </Radio.Group>
        </Col>
      </Row>
      {
        type === 'FORMULA' &&
        <Row>
          <Col span={24}>
            <Input.TextArea
              value={fx}
              placeholder="f(x) = ..."
              autoSize={{ minRows: 3, maxRows: 5 }}
              onChange={(e) => setFx(e.target.value)}
            />
          </Col>
        </Row>
      }
      
      <Button
        type="primary"
        onClick={() => onCreate(title, type, fx)}
      >
        创建
      </Button>
    </div>
  );
}

export default CreateFieldForm;
