
import React, { useState } from "react";
import {
  Input,
  Button
} from 'antd';
import {
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons';

import './edit-text.scss';

function EditTitle({
  text,
  onUpdate
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [editingText, setEditingText] = useState(text);

  function handleStartEditing() {
    setIsEditing(true);
    setEditingText(text);
  }

  if (isEditing) {
    return (
      <div className="component-edit-text">
        <Input
          type="text"
          value={editingText}
          onChange={(e) => setEditingText(e.target.value)}
        />
        <Button
          className="submit"
          icon={<CheckOutlined />}
          onClick={() => {
            onUpdate(editingText);
            setIsEditing(false);
          }}
        />
        <Button
          className="cancel"
          icon={<CloseOutlined />}
          onClick={() => {
            setEditingText(text);
            setIsEditing(false);
          }}
        />
      </div>
    );
  }

  return (
    <div className="component-edit-text" onClick={handleStartEditing}>
      <div className="read-display">{text}</div>
    </div>
  );
}

export default EditTitle;
