
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import {
  Row, Col,
  Form,
  Button,
  Input
} from 'antd';

import request from '../adapters/api';

import './index.scss';

function Calculator() {
  const [selectedModelId, setSelectedModelId] = useState(1);
  const [models, setModels] = useState(null);
  const [result, setResult] = useState(null);

  let selectedModel;
  if (models !== null) {
    selectedModel = models.find(
      (model) => model.id === selectedModelId
    );
  }

  useEffect(() => {
    (async () => {
      const response = await request('models');
      if (!response.error) {
        const body = await response.json();
        setModels(body.data);
      }
    })();
  }, []);

  if (
    models === null
  ) {
    return (
      <div>加载中...</div>
    );
  }

  async function calculate(values) {
    setResult(null);

    const response = await request(`models/${selectedModelId}/calculate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    });
    if (!response.error) {
      const body = await response.json();
      if (body.data) {
        setResult(body.data);
      } else if (body.error) {
        setResult(body.error);
      }
    }
  }

  function renderFormula(formula) {
    console.log('formula', formula);
    const {
      inputParameters, dataTableParameters, apiParameters
    } = selectedModel;

    const replaceInputParameters = `${formula}`.replace(
      /{{INPUT.([0-9]+)}}/g,
      (match, id) => {
        const field = inputParameters.find(
          (param) => `${param.id}` === `${id}`
        );
        if (!field) return <div data-param-type="input-param">未知参数</div>;
        console.log(field.title);
        return `<div data-param-type="input-param">${field.title}</div>`;
      }
    );
    console.log('replaceInputParameters', replaceInputParameters);

    const replaceDataTableParameters = replaceInputParameters.replace(
      /{{DATA_TABLE.([0-9]+)}}/g,
      (match, id) => {
        const field = dataTableParameters.find(
          (param) => `${param.id}` === `${id}`
        );
        if (!field) return <div data-param-type="input-param">未知参数</div>;
        console.log(field.title);
        return `<div data-param-type="input-param">${field.title}</div>`;
      }
    );
    console.log('replaceDataTableParameters', replaceDataTableParameters);
  
    const replaceApiParameters = replaceDataTableParameters.replace(
      /{{API.([0-9]+)}}/g,
      (match, id) => {
        const field = apiParameters.find(
          (param) => `${param.id}` === `${id}`
        );
        if (!field) return <div data-param-type="input-param">未知参数</div>;
        console.log(field.title);
        return `<div data-param-type="input-param">${field.title}</div>`;
      }
    );
    console.log('replaceApiParameters', replaceApiParameters);

    return (
      <div className="formula-display" dangerouslySetInnerHTML={{ __html: replaceApiParameters }}>
      </div>
    );
  }

  return (
    <div className="page-calculator">
      <div className="model-list">
        {
          models.map((model) => {
            const className = classnames({
              'model-list-item': true,
              'selected': model.id === selectedModelId
            })
            return (
              <div
                key={model.id}
                className={className}
                onClick={() => setSelectedModelId(model.id)}
              >
                {model.title}
              </div>
            );
          })
        }
      </div>
      {
        !selectedModel &&
        <div className="calculator-details empty">请选择一个模型</div>
      }
      {
        selectedModel &&
        <div className="calculator-details">
          <div className="inputs">
            <Form
              name="calculator"
              onFinish={calculate}
            >
              <Row gutter={20}>
                {
                  selectedModel.inputParameters.map((param, i) => (
                    <Col key={i} span={8}>
                      <Form.Item
                        label={param.title}
                        name={param.id}
                        rules={[
                          {
                            required: true,
                            message: '数值不得为空',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  ))
                }
              </Row>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  提交
                </Button>
              </Form.Item>
            </Form>
          </div>
          {
            result !== null &&
            <div className="result">
              <div className="divider"></div>
              <div className="content">
                <div className="title">计算结果</div>
                {
                  !result.formulae &&
                  <div>错误：{result.message}</div>
                }
                {
                  result.formulae &&
                  result.formulae.map((formula, i) => (
                    <div className="formula" key={i}>
                      <div className="result-row">
                        <div>{formula.title}：</div>
                        <div className="quote">
                          {
                            (formula.answer === undefined || formula.answer == null) ?
                            '计算错误' :
                            formula.answer.toFixed(4)
                          }
                        </div>
                      </div>
                      <div className="result-row">
                        <div>原公式：</div>
                        {renderFormula(formula.formulaBody)}
                      </div>
                      <div className="result-row">
                        <div>计算过程：</div>
                        <div>{formula.filledFormula}</div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          }
        </div>
      }
    </div>
  );
}

export default Calculator;
